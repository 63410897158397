import { createGlobalStyle } from 'styled-components'

import CSSReset from './CSSReset'
import Fonts from './fonts'
import { ThemeContainer } from './Theme'
import device from './utils/device'
import { BROWSER_DEFAULT_FONT_SIZE } from './utils/pixelsToRems'

const GlobalStyle = createGlobalStyle`
  ${Fonts}
  ${CSSReset}

  html, body {
    font-family: var(--font-family);
    font-size: ${BROWSER_DEFAULT_FONT_SIZE}px;
    background-color: white;

    @media ${device.xsmall} {
      font-size: 14px;
    }

    @media ${device.small} {
      font-size: 14px;
    }
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
  }
  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    padding-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    box-sizing: border-box;
  }

  :root {
    /* ********** */
    /*   COLORS   */
    /* ********** */
    --brand: ${({ theme }: ThemeContainer) => theme.colors.blue6};
    --blue1: ${({ theme }: ThemeContainer) => theme.colors.blue1};
    --blue2: ${({ theme }: ThemeContainer) => theme.colors.blue2};
    --blue3: ${({ theme }: ThemeContainer) => theme.colors.blue3};
    --blue4: ${({ theme }: ThemeContainer) => theme.colors.blue4};
    --blue5: ${({ theme }: ThemeContainer) => theme.colors.blue5};
    --blue6: ${({ theme }: ThemeContainer) => theme.colors.blue6};
    --orange: ${({ theme }: ThemeContainer) => theme.colors.orange};
    --red: ${({ theme }: ThemeContainer) => theme.colors.red};
    --yellow: ${({ theme }: ThemeContainer) => theme.colors.yellow};
    --lime: ${({ theme }: ThemeContainer) => theme.colors.lime};
    --green: ${({ theme }: ThemeContainer) => theme.colors.green};
    --turquoise: ${({ theme }: ThemeContainer) => theme.colors.turquoise};
    --paleGreen: ${({ theme }: ThemeContainer) => theme.colors.paleGreen};
    --grey1: ${({ theme }: ThemeContainer) => theme.colors.grey1};
    --grey2: ${({ theme }: ThemeContainer) => theme.colors.grey2};
    --grey3: ${({ theme }: ThemeContainer) => theme.colors.grey3};
    --dark1: ${({ theme }: ThemeContainer) => theme.colors.dark1};
    --dark2: ${({ theme }: ThemeContainer) => theme.colors.dark2};

    // Text
    --text: ${({ theme }: ThemeContainer) => theme.colors.dark2};

    /* ********** */
    /* TYPOGRAPHY */
    /* ********** */

    --font-family: ${({ theme }: ThemeContainer) =>
      // theme.typography.fontFamily.Axiforma.name};
      theme.typography.fontFamily.Inter.name};

    // Weights
    --font-weight-light: ${({ theme }: ThemeContainer) =>
      theme.typography.fontFamily.Inter.weight.light};
    --font-weight-regular: ${({ theme }: ThemeContainer) =>
      // theme.typography.fontFamily.Axiforma.weight.regular};
      theme.typography.fontFamily.Inter.weight.regular};
    --font-weight-medium: ${({ theme }) =>
      // theme.typography.fontFamily.Axiforma.weight.medium};
      theme.typography.fontFamily.Inter.weight.medium};
    --font-weight-bold:  ${({ theme }) =>
      // theme.typography.fontFamily.Axiforma.weight.bold};
      theme.typography.fontFamily.Inter.weight.bold};
    --font-weight-extraBold:  ${({ theme }) =>
      // theme.typography.fontFamily.Axiforma.weight.extraBold};
      theme.typography.fontFamily.Inter.weight.extraBold};

    // Sizes
    --font-size-heading1: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.heading1};
    --font-size-heading2: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.heading2};
    --font-size-heading3: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.heading3};
    --font-size-subtitle: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.subtitle};
    --font-size-xlarge: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.xlarge};
    --font-size-large: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.large};
    --font-size-medium: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.medium};
    --font-size-small: ${({ theme }: ThemeContainer) =>
      theme.typography.fontSize.small};

    // Line-Heights
    --line-height-heading1: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.heading1};
    --line-height-heading2: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.heading2};
    --line-height-subtitle: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.subtitle};
    --line-height-xlarge: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.xlarge};
    --line-height-large: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.large};
    --line-height-medium: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.medium};
    --line-height-small: ${({ theme }: ThemeContainer) =>
      theme.typography.lineHeight.small};
    

    font-family: var(--font-family);
    color: var(--text);

    h1 {
      font-size: var(--font-size-heading1);
      font-weight: var(--font-weight-bold);
      line-height: var(--line-height-heading1);
    }
    h2 {
      font-size: var(--font-size-heading2);
      font-weight: var(--font-weight-bold);
      line-height: var(--line-height-heading2);
    }
    h3 {
      font-size: var(--font-size-heading3);
      font-weight: var(--font-weight-bold);
    }
    p.subtitle {
      font-size: var(--font-size-xlarge);
      font-weight: var(--font-weight-regular);
      line-height: var(--line-height-xlarge);
    }
    p.cardTitle {
      font-size: var(--font-size-xlarge);
      font-weight: var(--font-weight-medium);
      line-height: var(--line-height-xlarge);
    }
    input {
      font-family: var(--font-family);
      color: var(--text);
      font-weight: var(--font-weight-regular);
      font-size: var(--font-size-medium);
    }
    textarea {
      font-family: var(--font-family);
      color: var(--text);
      font-weight: var(--font-weight-regular);
      font-size: var(--font-size-medium);
    }

    /* ********** */
    /* ANIMATIONS */
    /* ********** */
    --ease-in-quint: ${({ theme }: ThemeContainer) =>
      theme.animation.easing.easeInQuint};
    --ease-out-quint: ${({ theme }: ThemeContainer) =>
      theme.animation.easing.easeOutQuint};
    --ease-in-out-quint: ${({ theme }: ThemeContainer) =>
      theme.animation.easing.easeInOutQuint};
  }
`

export default GlobalStyle
