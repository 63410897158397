import { ThemeProps } from 'styled-components'
import pixelsToRems from './utils/pixelsToRems'

export enum FontFamily {
  Axiforma = 'Axiforma',
  Inter = 'Inter',
}

export enum AxiformaFontWeight {
  light = '200',
  regular = 'normal',
  medium = '500',
  semibold = '600',
  bold = 'bold',
  extraBold = '800',
}

export enum InterFontWeight {
  light = '300',
  regular = 'normal',
  medium = '500',
  semibold = '600',
  bold = 'bold',
  extraBold = '800',
}

export interface FontSpecs {
  name: string
  weight: Record<keyof typeof InterFontWeight, string>
}

export enum FontSize {
  heading1,
  heading2,
  heading3,
  subtitle,
  xlarge,
  large,
  medium,
  small,
  xsmall,
}

export enum LineHeight {
  heading1,
  heading2,
  subtitle,
  xlarge,
  large,
  medium,
  small,
}

export interface Typography {
  fontFamily: Record<FontFamily, FontSpecs>
  fontSize: Record<keyof typeof FontSize, string>
  lineHeight: Record<keyof typeof LineHeight, string>
}

export enum ColorName {
  blue1,
  blue2,
  blue3,
  blue4,
  blue5,
  blue6,
  orange,
  red,
  yellow,
  lime,
  green,
  turquoise,
  paleGreen,
  grey1,
  grey2,
  grey3,
  dark1,
  dark2,
  linkedin,
  twitter,
}

export enum SpacingSize {
  xsmall,
  small,
  medium,
  large,
  xlarge,
  huge,
}

export interface Animation {
  easing: {
    easeInQuint: string
    easeOutQuint: string
    easeInOutQuint: string
  }
}

export enum BorderRadiusSize {
  medium,
  large,
}

export interface Theme {
  typography: Typography
  colors: Record<keyof typeof ColorName, string>
  spacing: Record<keyof typeof SpacingSize, string>
  animation: Animation
  borderRadius: Record<keyof typeof BorderRadiusSize, string>
}

export type ThemeContainer = ThemeProps<Theme>

export const defaultTheme: Theme = {
  typography: {
    fontFamily: {
      Axiforma: {
        name: FontFamily.Axiforma,
        weight: {
          light: AxiformaFontWeight.light,
          regular: AxiformaFontWeight.regular,
          medium: AxiformaFontWeight.medium,
          semibold: AxiformaFontWeight.semibold,
          bold: AxiformaFontWeight.bold,
          extraBold: AxiformaFontWeight.bold,
        },
      },
      Inter: {
        name: FontFamily.Inter,
        weight: {
          light: InterFontWeight.light,
          regular: InterFontWeight.regular,
          medium: InterFontWeight.medium,
          semibold: InterFontWeight.semibold,
          bold: InterFontWeight.bold,
          extraBold: InterFontWeight.bold,
        },
      },
    },
    fontSize: {
      heading1: pixelsToRems(56),
      heading2: pixelsToRems(36),
      heading3: pixelsToRems(30),
      subtitle: pixelsToRems(28),
      xlarge: pixelsToRems(24),
      large: pixelsToRems(18),
      medium: pixelsToRems(16),
      small: pixelsToRems(14),
      xsmall: pixelsToRems(12),
    },
    lineHeight: {
      heading1: pixelsToRems(75),
      heading2: pixelsToRems(60),
      subtitle: pixelsToRems(50),
      xlarge: pixelsToRems(45),
      large: pixelsToRems(41),
      medium: pixelsToRems(32),
      small: pixelsToRems(28),
    },
  },
  colors: {
    blue1: '#2675A6', // NEW
    blue2: '#102040', // NEW
    blue3: '#00A5FF',
    blue4: '#116DF9',
    blue5: '#3D8BF2', // NEW
    blue6: '#0016A8',
    orange: '#F7C8AB',
    red: '#E47C6C',
    yellow: '#F5F37B',
    lime: '#D9FFA2',
    green: '#48E4A2',
    turquoise: '#48A6A9',
    paleGreen: '#D1E9E9',
    grey1: '#F9F9FA',
    grey2: '#F1F1F1',
    grey3: '#707070',
    dark1: '#2F2E40',
    dark2: '#22222F',
    linkedin: '#0072b1',
    twitter: '#00acee',
  },
  spacing: {
    xsmall: pixelsToRems(8),
    small: pixelsToRems(16),
    medium: pixelsToRems(32),
    large: pixelsToRems(40),
    xlarge: pixelsToRems(80),
    huge: pixelsToRems(100),
  },
  animation: {
    easing: {
      easeInQuint: 'cubic-bezier(0.64, 0, 0.78, 0)',
      easeOutQuint: 'cubic-bezier(0.22, 1, 0.36, 1)',
      easeInOutQuint: 'cubic-bezier(0.83, 0, 0.17, 1)',
    },
  },
  borderRadius: {
    medium: pixelsToRems(10),
    large: pixelsToRems(20),
  },
}
